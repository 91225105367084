<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import { sparklineChartData, salesDonutChart, radialBarChart } from "./data";
import { statisticsMethods } from "../../state/helpers/statistics";

export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
  },
  data() {
    return {
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      image: require("@/assets/images/services-icon/01.png"),
      statData: [
        {
          title: "Total Properties",
          value: "1,685",
          subText: "+ 12%",
          color: "primary"
        },
      ],
      statistics: {},
    };
  },
  mounted() {
    this.getAllStatistics()
  },
  methods: {
    ...statisticsMethods,
    getAllStatistics() {
      return this.allStatistics().then((response) => {
        // eslint-disable-next-line no-console
        this.statistics = response.data
      })
    },
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Dashboard</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-xl-3 col-md-6 mb-3">
        <!-- stat box start -->
        <div class="stat_box_view" @click="$router.push({name: 'clients'}).catch(() => {})">
          <div class="title_state_box">
            <h2 class="text-success">{{ statistics.total_clients }}</h2>
            <h5>Total Clients</h5>
          </div>

          <div class="icon__view">
            <i class="fas fa-users"></i>
          </div>
        </div>
        <!-- stat box end -->
      </div>

      <div class="col-xl-3 col-md-6 mb-3">
        <!-- stat box start -->
        <div class="stat_box_view" @click="$router.push({name: 'tenants'}).catch(() => {})">
          <div class="title_state_box">
            <h2 class="text-success">{{ statistics.total_tenant }}</h2>
            <h5>Total Tenants</h5>
          </div>

          <div class="icon__view">
            <i class="fas fa-house-user"></i>
          </div>
        </div>
        <!-- stat box end -->
      </div>

      <div class="col-xl-3 col-md-6 mb-3">
        <!-- stat box start -->
        <div class="stat_box_view" @click="$router.push({name: 'vendors'}).catch(() => {})">
          <div class="title_state_box">
            <h2 class="text-success">{{ statistics.total_vendor }}</h2>
            <h5>Total Vendors</h5>
          </div>

          <div class="icon__view">
            <i class="fas fa-people-carry"></i>
          </div>
        </div>
        <!-- stat box end -->
      </div>


      <div class="col-xl-3 col-md-6 mb-3">

        <!-- stat box start -->
        <div class="stat_box_view" @click="$router.push({name: 'properties'}).catch(() => {})">
          <div class="title_state_box">
            <h2 class="text-success">{{ statistics.total_property }}</h2>
            <h5>Total Properties</h5>
          </div>

          <div class="icon__view">
            <i class="fas fa-hotel"></i>
          </div>
        </div>
        <!-- stat box end -->
      </div>


    </div>
    <!-- end row -->
  </Layout>
</template>